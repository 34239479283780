import Vue from 'vue'
import Router from 'vue-router'
// Containers
const TheContainer = () => import('@/containers/TheContainer')
// Views
// const Dashboard = () => import('@/views/Dashboard')
// const Colors = () => import('@/views/theme/Colors')
// const Typography = () => import('@/views/theme/Typography')
// const Charts = () => import('@/views/charts/Charts')
// const Widgets = () => import('@/views/widgets/Widgets')
// Views - Components
// const Cards = () => import('@/views/base/Cards')
// const Forms = () => import('@/views/base/Forms')
// const Switches = () => import('@/views/base/Switches')
// const Tables = () => import('@/views/base/Tables')
// const Tabs = () => import('@/views/base/Tabs')
// const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
// const Carousels = () => import('@/views/base/Carousels')
// const Collapses = () => import('@/views/base/Collapses');
// const Jumbotrons = () => import('@/views/base/Jumbotrons')
// const ListGroups = () => import('@/views/base/ListGroups')
// const Navs = () => import('@/views/base/Navs')
// const Navbars = () => import('@/views/base/Navbars')
// const Paginations = () => import('@/views/base/Paginations')
// const Popovers = () => import('@/views/base/Popovers')
// const ProgressBars = () => import('@/views/base/ProgressBars')
// const Tooltips = () => import('@/views/base/Tooltips')
// Views - Buttons
// const StandardButtons = () => import('@/views/buttons/StandardButtons')
// const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
// const Dropdowns = () => import('@/views/buttons/Dropdowns')
// const BrandButtons = () => import('@/views/buttons/BrandButtons')
// Views - Icons
// const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
// const Brands = () => import('@/views/icons/Brands')
// const Flags = () => import('@/views/icons/Flags')
// Views - Notifications
// const Alerts = () => import('@/views/notifications/Alerts')
// const Badges = () => import('@/views/notifications/Badges')
// const Modals = () => import('@/views/notifications/Modals')
/** -----------------APRD----------------------- */
const PatientList = () => import('@/views/aprod/patient/PatientList');
const PatientSummary = () => import('@/views/aprod/patient/PatientSummary');
const PatientManage = () => import('@/views/aprod/patient/PatientManage');
        // const AgencyWithBranch = () => import('@/component/AgencyWithBranch');
        // const HospitalWithDoctor = () => import('@/component/HospitalWithDoctor');
        //  const Hospital = () => import('@/component/Hospital');

const PatientArchive = () => import('@/views/aprod/patient/PatientArchive');

const DeviceList = () => import('@/views/aprod/device/DeviceList')
const DeviceHealthInfo = () => import('@/views/aprod/device/DeviceHealthInfo')
const GatewayFirmwareManage = () => import('@/views/aprod/device/GatewayFirmwareManage')
    //const GatewayFormFirmware = () => import('@/component/GatewayForFirmware')

const GatewayFirmware = () => import('@/views/aprod/GatewayFirmware')
const NoticeList = () => import('@/views/aprod/notice/NoticeList')
const NoticeRegist = () => import('@/views/aprod/notice/NoticeRegist')

    const PatientCharts = () => import('@/views/aprod/PatientCharts')
    const PatientCharts1= () => import('@/views/aprod/PatientCharts1')
    const OxChartPage= () => import('@/views/aprod/OxChartPage')

    // const VueTable2Test = () => import('@/views/aprod/VueTable2Test')

// const VueTable2Test = () => import('@/component/parts/DeviceSummaryDays')


const DeviceSummary = () => import('@/views/aprod/statistics/DeviceSummary')
const AccessLog = () => import('@/views/aprod/statistics/AccessLog')

const UserManage = () => import('@/views/aprod/regist/UserManage')
const HospitalManage = () => import('@/views/aprod/regist/HospitalManage')
const AgencyManage = () => import('@/views/aprod/regist/AgencyManage')
// const IdManage = () => import('@/views/aprod/regist/IdManage')
const IdManage = () => import('@/views/aprod/regist/IdManage')
const AgencyBranchManage = () => import('@/views/aprod/regist/AgencyBranchManage')

const QrCode = () => import('@/views/aprod/QrCode')

// Views - Pages
const Page404 = () => import('@/views/aprod/pages/Page404')
const Page500 = () => import('@/views/aprod/pages/Page500')
const Login = () => import('@/views/aprod/pages/Login')
const PatientSummaryWide = () => import('@/views/aprod/pages/PatientSummaryWide')
const PatientSummaryFixed = () => import('@/views/aprod/pages/PatientSummaryFixed')
const PatientSummaryPrint = () => import('@/views/aprod/pages/PatientSummaryPrint')

const UserActive = () => import('@/views/aprod/pages/UserActive')
const UserInfo = () => import('@/views/aprod/pages/UserInfo')
const Register = () => import('@/views/aprod/pages/Register')
const PasswordReset = () => import('@/views/aprod/pages/passwordReset')
/** -----------------PRD END----------------------- */
// const PatientCharts = () => import('@/views/PatientCharts')
Vue.use(Router);

const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: getRoutes()
});

router.beforeEach((to, from, next) => {
    console.log("router.beforeEach===================");
    // if (to.path == "/login") {
    //     store.commit("setUser", {});
    // }
    // if(to.path.indexOf("/user/passwordReset/") ==0){
    //     next();
    // }else if (to.path != "/login" && store.state.user.role === undefined) {
    //     console.log("store.state.user.role", store.state.user.role)
    //     next("/");
    // } else {
    //     //console.log("router.params",to.params);
    //     //store.commit("saveParam",this.$route.path,router.params),
         next();
    // }
});

function getRoutes() {

    const ALL = ['ADMINISTRATOR', 'REGISTER','PROVIDER','DOCTOR','ALL'];
    let routes = [
        {
            path: '/pages',
            name: 'Home',
            mode: ['prod', 'dev'],
            roles: ALL,
            redirect: '/pages/agencies',
            component: TheContainer,
            children: [
                {
                    path: 'notice',
                    redirect: '/notice/list',
                    meta: {label: 'notice'},
                    mode: ['prod', 'dev'],
                    roles: ALL,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [{
                        path: 'list',
                        name: 'NoticeList',
                        meta:{title:'お知らせ一覧'},
                        component: NoticeList,
                        mode: ['prod', 'dev'],
                        roles: ALL,
                    }, {
                        path: 'regist',
                        name: 'NoticeRegist',
                        meta:{title:'お知らせ登録'},
                        component: NoticeRegist,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    }]
                },{
                    path: 'patient',
                    redirect: '/patient/list',
                    meta: {label: 'Users'},
                    mode: ['prod', 'dev'],
                    roles: ALL,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [{
                        path: 'list',
                        name: 'PatientList',
                        component: PatientList,
                        mode: ['prod', 'dev'],
                        roles: ALL,
                    },{
                        path: 'summary/:id',
                        name: 'PatientSummary',
                        component: PatientSummary,
                        mode: ['prod', 'dev'],
                        roles: ALL,
                    },{
                        path: 'manage',
                        name: 'PatientManage',
                        component: PatientManage,
                        mode: ['prod', 'dev'],
                        roles: ALL,
                    },{
                        path: 'archive',
                        name: 'PatientArchive',
                        component: PatientArchive,
                        mode: ['prod', 'dev'],
                        roles: ALL,
                    }]
                },
                {
                    path: 'qrcode',
                    name: 'QrCode',
                    component: QrCode,
                    mode: ['prod', 'dev'],
                    roles: ['ADMINISTRATOR', 'REGISTER','PROVIDER'],
                },
                {
                    path: 'statistics',
                    redirect: '/statistics/devicesummary',
                    meta: {label: 'statistics'},
                    mode: ['prod', 'dev'],
                    roles: ['ADMINISTRATOR', 'REGISTER'],
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [{
                        path: 'devicesummary',
                        name: 'DeviceSummary',
                        component: DeviceSummary,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    },{
                        path: 'accesslog',
                        name: 'AccessLog',
                        component: AccessLog,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    }]
                },{
                    path: 'regist',
                    redirect: '/regist/userManage',
                    meta: {label: 'regist'},
                    mode: ['prod', 'dev'],
                    roles: ['ADMINISTRATOR', 'REGISTER' ],
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [{
                        path: 'userManage',
                        name: 'UserManage',
                        component: UserManage,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    },{
                        path: 'hospitalManage',
                        name: 'HospitalManage',
                        component: HospitalManage,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'PROVIDER'],
                    },{
                        path: 'agencyManage',
                        name: 'AgencyManage',
                        component: AgencyManage,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    },/*{
                        path: 'idManage',
                        name: 'IdManage',
                        component: IdManage,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    }*/,{
                        path: 'IdManage',
                        name: 'IdManage',
                        component: IdManage,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    },{
                        path: 'agencyBranchManage/:id',
                        name: 'AgencyBranchManage',
                        component: AgencyBranchManage,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    }]
                },
                {
                    path: 'device',
                    redirect: '/device/list',
                    meta: {label: 'Users'},
                    mode: ['prod', 'dev'],
                    roles: ['ADMINISTRATOR', 'REGISTER','PROVIDER'],
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [{
                        path: 'list',
                        name: 'DeviceList',
                        component: DeviceList,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER','PROVIDER'],
                    },{
                        path: 'healthinfo',
                        name: 'DeviceHealthInfo',
                        component: DeviceHealthInfo,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER','PROVIDER'],
                    },{
                        path: 'firmwaremanage',
                        name: 'GatewayFirmwareManage',
                        component: GatewayFirmwareManage,
                        mode: ['prod', 'dev'],
                        roles: ['ADMINISTRATOR', 'REGISTER'],
                    }]
                },

                {
                    path: 'PatientCharts',
                    name: 'PatientCharts',
                    component: PatientCharts,
                    mode: [ 'dev'],
                    roles: ALL,
                },{
                    path: 'PatientCharts1',
                    name: 'PatientCharts1',
                    component: PatientCharts1,
                    mode: [ 'dev'],
                    roles: ALL,
                },{
                    path: 'OxChartPage',
                    name: 'OxChartPage',
                    component: OxChartPage,
                    mode: [ 'dev'],
                    roles: ALL,
                },
                // {
                //     path: 'VueTable2Test',
                //     name: 'VueTable2Test',
                //     component: VueTable2Test,
                //     mode: [ 'dev'],
                //     roles: ALL,
                // },
                {
                    path: 'userInfo',
                    name: 'userInfo',
                    component: UserInfo,
                    mode: ['prod', 'dev'],
                    roles: ALL,
                }, {
                    path: 'GatewayFirmware',
                    name: 'GatewayFirmware',
                    component: GatewayFirmware,
                    mode: ['prod', 'dev'],
                    roles: ['ADMINISTRATOR', 'REGISTER'],
                },
                // {
                //     path: 'patient',
                //     redirect: '/patient/list',
                //     name: 'patient',
                //     mode: ['prod', 'dev'],
                //     roles: ['ADMINISTRATOR', 'PROVIDER', 'DOCTOR'],
                //     component: {
                //         render(c) {
                //             return c('router-view')
                //         }
                //     },
                //     children: [
                //         {
                //             path: 'list',
                //             name: 'PatientList',
                //             component: PatientList,
                //             mode: ['prod', 'dev'],
                //             roles: ['ADMINISTRATOR', 'PROVIDER', 'DOCTOR'],
                //         }]
                // },
                // {
                //     path: 'dashboard',
                //     name: 'Dashboard',
                //     mode: ['dev'],
                //     roles: ['ADMINISTRATOR', 'PROVIDER', 'DOCTOR'],
                //     component: Dashboard
                // },
                {
                    path: 'theme',
                    redirect: '/theme/colors',
                    name: 'Theme',
                    mode: ['dev'],
                    roles: ALL,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        // {
                        //     path: 'colors',
                        //     name: 'Colors',
                        //     component: Colors,
                        //     mode: ['prod', 'dev'],
                        //     roles: ALL,
                        // },
                        // {
                        //     path: 'typography',
                        //     name: 'Typography',
                        //     component: Typography,
                        //     mode: ['prod', 'dev'],
                        //     roles: ALL,
                        // }
                    ]
                },
                // {
                //     path: 'charts',
                //     name: 'Charts',
                //     mode: ['dev'],
                //     roles: ALL,
                //     component: Charts
                // },
                // {
                //     path: 'widgets',
                //     name: 'Widgets',
                //     mode: ['dev'],
                //     roles: ALL,
                //     component: Widgets
                // },
                // {
                //     path: 'base',
                //     redirect: '/base/cards',
                //     name: 'Base',
                //     mode: ['dev'],
                //     roles: ALL,
                //     component: {
                //         render(c) {
                //             return c('router-view')
                //         }
                //     },
                //     children: [
                //         {
                //             path: 'cards',
                //             name: 'Cards',
                //             component: Cards,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'forms',
                //             name: 'Forms',
                //             component: Forms,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'switches',
                //             name: 'Switches',
                //             component: Switches,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'tables',
                //             name: 'Tables',
                //             component: Tables,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'tabs',
                //             name: 'Tabs',
                //             component: Tabs,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'breadcrumbs',
                //             name: 'Breadcrumbs',
                //             component: Breadcrumbs,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'carousels',
                //             name: 'Carousels',
                //             component: Carousels,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'collapses',
                //             name: 'Collapses',
                //             component: Collapses,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'jumbotrons',
                //             name: 'Jumbotrons',
                //             component: Jumbotrons,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'list-groups',
                //             name: 'List Groups',
                //             component: ListGroups,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'navs',
                //             name: 'Navs',
                //             component: Navs,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'navbars',
                //             name: 'Navbars',
                //             component: Navbars,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'paginations',
                //             name: 'Paginations',
                //             component: Paginations,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'popovers',
                //             name: 'Popovers',
                //             component: Popovers,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'progress-bars',
                //             name: 'Progress Bars',
                //             component: ProgressBars,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'tooltips',
                //             name: 'Tooltips',
                //             component: Tooltips,
                //             mode: ['dev'],
                //             roles: ALL,
                //         }
                //     ]
                // },
                // {
                //     path: 'buttons',
                //     redirect: '/buttons/standard-buttons',
                //     name: 'Buttons',
                //     mode: ['dev'],
                //     roles: ALL,
                //     component: {
                //         render(c) {
                //             return c('router-view')
                //         }
                //     },
                //     children: [
                //         {
                //             path: 'standard-buttons',
                //             name: 'Standard Buttons',
                //             component: StandardButtons,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'button-groups',
                //             name: 'Button Groups',
                //             component: ButtonGroups,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'dropdowns',
                //             name: 'Dropdowns',
                //             component: Dropdowns,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'brand-buttons',
                //             name: 'Brand Buttons',
                //             component: BrandButtons,
                //             mode: ['dev'],
                //             roles: ALL,
                //         }
                //     ]
                // },
                // {
                //     path: 'icons',
                //     redirect: '/icons/coreui-icons',
                //     name: 'CoreUI Icons',
                //     mode: ['dev'],
                //     roles: ALL,
                //     component: {
                //         render(c) {
                //             return c('router-view')
                //         }
                //     },
                //     children: [
                //         {
                //             path: 'coreui-icons',
                //             name: 'Icons library',
                //             component: CoreUIIcons,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'brands',
                //             name: 'Brands',
                //             component: Brands,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'flags',
                //             name: 'Flags',
                //             component: Flags,
                //             mode: ['dev'],
                //             roles: ALL,
                //         }
                //     ]
                // },
                // {
                //     path: 'notifications',
                //     redirect: '/notifications/alerts',
                //     name: 'Notifications',
                //     mode: ['dev'],
                //     roles: ALL,
                //     component: {
                //         render(c) {
                //             return c('router-view')
                //         }
                //     },
                //     children: [
                //         {
                //             path: 'alerts',
                //             name: 'Alerts',
                //             component: Alerts,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'badges',
                //             name: 'Badges',
                //             component: Badges,
                //             mode: ['dev'],
                //             roles: ALL,
                //         },
                //         {
                //             path: 'modals',
                //             name: 'Modals',
                //             component: Modals,
                //             mode: ['dev'],
                //             roles: ALL,
                //         }
                //     ]
                // }
            ]
        }, {
            path: '/',
            redirect: '/login',
            name: 'Pages',
            mode: ['prod', 'dev'],
            roles: ALL,
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: Login,
                    mode: ['prod', 'dev'],
                    roles: ALL,
                },
                {
                    path: 'user/activate/:activeToken',
                    name: 'userActive',
                    component: UserActive,
                    mode: ['prod', 'dev'],
                    roles: ALL
                },
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404,
                    mode: ['prod', 'dev'],
                    roles: ALL
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500,
                    mode: ['prod', 'dev'],
                    roles: ALL
                },
                {
                    path: 'PatientSummaryWide/:id',
                    name: 'PatientSummaryWide',
                    component: PatientSummaryWide,
                    mode: ['prod', 'dev'],
                    roles: ALL
                },{
                    path: 'PatientSummaryFixed/:id',
                    name: 'PatientSummaryFixed',
                    component: PatientSummaryFixed,
                    mode: ['prod', 'dev'],
                    roles: ALL
                },{
                    path: 'PatientSummaryPrint/:patientId/:chartType/:dayCount/:chartTo',
                    name: 'PatientSummaryPrint',
                    component: PatientSummaryPrint,
                    mode: ['prod', 'dev'],
                    roles: ALL
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register,
                    mode: ['prod', 'dev'],
                    roles: ALL,
                }, {
                    path: 'user/passwordReset/:loginId/:token',
                    name: 'passwordReset',
                    component: PasswordReset,
                    mode: ['prod', 'dev'],
                    roles: ALL,
                }
            ]
        },
        {
            path: '*',
            redirect: '/',
            component: Login,
            mode: ['prod', 'dev'],
            roles: ALL
        }
        // {
        //   path: '*',
        //   name: 'Page404NonePage',
        //   mode:['prod','dev'],
        //   component: Page404
        // }
    ];
    // TODO ログイン後にも設定し直す必要があるけどやり方が不明
    // let result = treeRoute(Object.assign([],routes),process.env.VUE_APP_MODE,store.state.user.role || 'ALL');
    // let result = treeRoute(Object.assign([],routes),process.env.VUE_APP_MODE,process.env.VUE_APP_MODE,store.state.user.role || 'ALL');
    // console.log("routes ===================",store.state.user.role,JSON.stringify(result));
    // return result;
    //console.log("============================router",JSON.stringify(result));
    // return Object.assign([],result);
    return Object.assign([],routes);
}

function treeRoute(items,mode,role){
    if(typeof items != 'object' ){
        return items;
    }
    let filterNode = items.filter((nd) => {
        if(nd.mode.includes(mode) && nd.roles.includes(role)){
            return true;
        }
        return false;
    });
    for(let node of filterNode){
        if(typeof node.children != 'undefined'){
            node.children = treeRoute(node.children,mode,role);
        }
    }
    return filterNode;
}

export default router